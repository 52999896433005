.attachfile .link_input_container {
    width: 100%;
}
.header_portion_information .header_title{
    font-family: Montserrat,sans-serif;
    font-size: 45px;
    font-weight: 800;
    margin-bottom: 12px;
    text-align: center;
}

.header_portion_information .header_description{
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    text-align: center;
}

.header_portion_information_video .header_title{
    font-family: Montserrat,sans-serif;
    font-size: 45px;
    font-weight: 800;
    margin-bottom: 12px;
    text-align: center;
}

.header_portion_information_video .header_description{
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    text-align: center;
}

.header_portion_information_video .player-wrapper{
    height: 100%;
    width: 100%;

}

.main_video_wraper{
    border: 20px solid #bccbd1;
    position: relative;

}

