.LandingPage2BG{background:  url('https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage2/underpatment_banner_BGMain.webp') no-repeat left top;}
.l2_top_banner{  overflow: hidden;}
.l2_top_bannerBG{ background: url('https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage2/landing_pg2_aspire_bnnr_img.webp') no-repeat 64% top;}


.l2Wrapper{ width: 1790px; margin: 0 auto; display: flex; flex-wrap: wrap; justify-content: space-between;     align-items: flex-end;}

.l2_top_banner_left{ width: 1208px;}

.newscrollbtn{width: auto;
 
    max-width: 100%;
    text-align: center;
    line-height: 35px;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    background: rgb(64,64,64);
    background: linear-gradient(180deg, rgba(64,64,64,1) 0%, rgba(7,7,7,1) 100%);
    border-radius: 10px;
    text-transform: capitalize;
    font-weight: 500;
    display: block;
    margin-top: 18px;
    font-size: 30px;
    color: #fff;
    cursor: pointer;
    text-decoration: none;}

.l2_logowrapper{ margin: 28px 0 32px 0;}

.l2_logowrapper img{ width: 363px; max-width: 90%;}

.l2_top_banner_text{ width: 812px; 
     background: url('https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage2/underpatment_banner_BG2.webp'); background-position: left top; background-repeat: no-repeat; border-radius: 10px; background-size: cover;  background-color: #01bdfd;  overflow: hidden;   padding-bottom: 10px;  box-shadow: 0 0 5px #787777;} 

.l2_top_banner_text h2{ margin: 0; padding: 30px 10px 25px 10px; text-transform: uppercase; font-size: 62px;     line-height: 50px;  color: #1b1b1b;text-align: center;}  

.l2_top_banner_textCon{  padding: 0 8px 8px 8px; }

.l2_top_banner_textCon label{ display: block; margin: 0; border: solid 1px #fff;  background: url('https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage2/underpatment_banner_BG1.webp') no-repeat center top; background-size: cover; border-radius: 10px; padding: 35px;}

.l2_top_banner_textCon label p{ margin: 0; padding: 0; font-size: 35px; line-height: 38px; color: #fff; font-weight: 500;}
/* .l2_top_banner_textCon label p span{ color: #fff725;} */

.l2_top_banner_text h3{ margin: 15px; padding: 0; font-size: 24px; color: #000000; font-weight: 500; line-height: 32px;}

.l2_top_banner_text h3 span{ color: #035684;}

.l2_top_banner_arrowCon{ background: url('https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage/images/l_page1_banner_arrow.webp') no-repeat left top; height: 185px; width: 100%; margin-left: -10px;     background-size: contain;}

.l2_top_banner_arrowCon h4{ margin: 50px 0 0 0; padding: 48px 30px; display: flex;   flex-wrap: wrap; align-items: center; height: 100%; 
    color: #fff;
    text-transform: uppercase;
    font-size: 40px;
    line-height: 70px;
    font-weight: 600;
    text-shadow: 1px 1px 4px rgb(0 0 0 / 70%);
}  

.l2_top_banner_right{ width: 500px; margin:25px 0 28px 0;  }

.l2_top_banner_right .l2form_style{background: rgba(206, 222, 228, 0.5); border: solid 1px #fff; border-radius: 5px; padding: 10px;}
.l2form_box{ background: #fff; padding: 10px;  border: solid 1px #fff;  border-radius: 4px;}

.l2form_box .l2form_style_heading{ background: rgb(87,254,246);
    background: linear-gradient(180deg, rgba(87,254,246,1) 0%, rgba(1,197,254,1) 100%); border: solid 1px #fff; border-radius: 2px; padding: 25px 5px; text-align: center;}

.l2form_box .l2form_style_heading h2{ margin: 0; padding: 0; font-size: 30px; line-height: 33px; text-transform: uppercase; color: #000; text-shadow: 0 0 1px #ffec15;}

.l2form_style .MuiFormControl-root{width: 100%;}
.l2form_style .formfild{margin-bottom: 16px;}
.l2form_style .MuiInputBase-root{border-radius: 0;}
.l2form_style .MuiOutlinedInput-notchedOutline{border: 1px solid #76838a !important;}
.l2form_style .formfild input{background-color: #919699;     color: #fff;     padding: 5px 14px;  height:40px;  }
.l2form_style .formfilddec{margin-bottom: 16px;}
.l2form_style .formfilddec textarea{background-color: #919699;     color: #fff;   outline: none!important;   padding: 5px 14px;  height: 80px!important;  resize: none; }
.l2form_style .formfilddec textarea::placeholder{ color: #fff;  font-weight: var(--main-font-Bold);   font-size: 14px;   line-height: 18px;     display: flex;
    flex-wrap: wrap; }

.l2form_style .MuiFormLabel-root {
    font-weight: var(--main-font-Bold);
    color: #fff !important;
    word-wrap: break-word; 
    top: -16px;
    text-overflow: inherit;
    white-space: normal;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    flex-wrap: wrap; 
    height: 100%;   align-items: center;
}
.l2form_style .MuiFormLabel-filled{display: none;}
.l2form_style label.Mui-focused{display: none;}
.l2form_style legend{display: none;}
.l2form_style fieldset{top: 0 !important;}
.l2form_style .form_radio .MuiFormGroup-root{display:flex;flex-direction:row;align-items:center}
.l2form_style .form_radio .MuiFormControlLabel-root{color: #fff;}
.l2form_style .form_radio .MuiFormControlLabel-root .MuiSvgIcon-root path{display:none}
 
.l2submitbtnsection button{text-shadow: 0 0 2px #0f3a58; background: rgb(12,209,255);
    background: linear-gradient(180deg, rgba(12,209,255,1) 0%, rgba(1,178,255,1) 100%);border:none;border-radius:5px;font-size:40px;line-height:40px;color:#fff;font-weight:var(--main-font-SemiBold);text-transform:uppercase;padding:20px;width:calc(100%)}
.l2form_style .form_radio, .l2form_style .form_text{margin-bottom: 15px;}
.l2form_style .form_text .MuiTypography-root{font-size: 18px;}
.l2form_style  .form_text p{color:#fff;font-size:18px;line-height:24px;font-weight:var(--main-font-SemiBold);text-shadow:1px 1px 1px #76838a}
.l2form_style .formWrapperClass{padding: 15px 5px 5px 5px;}

/* .l2form_style .css-1wc848c-MuiFormHelperText-root.Mui-error{ position: absolute; right: 0;} */

.l2_block2{ background: url('https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage2/underpatment_block2_img1.webp') no-repeat 65px top;  position: relative; }

.l2_block2_bg{ padding-left: 720px;  padding-top: 25px; padding-bottom: 45px; }
 

 

.l2_block2_text h2{ background: url('https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage2/underpatment_block2_img2.webp') no-repeat 95% top; margin: 0; padding: 0; font-size: 48px; line-height: 50px;  color: #262626; padding-right: 25%;  min-height: 117px;}

.l2_block2_text h3{ margin: 0; padding: 25px 0; font-size: 35px; line-height: 35px; color: #262626; }
.l2_block2_text h3 span{ color: #018de4;}


.l2_block2_text p{ margin: 0; padding:0; font-size: 24px; line-height: 34px; color: #000000; font-size: 500; }
.l2_block2_text p span{ color: #01b5ff;}

.l2_block3_con{ background: url('https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage2/underpatment_block3_bg1.webp') no-repeat right top; background-size: cover;  border-radius: 10px; padding: 35px; position: relative; }

.l2_block3_con .l2_block3_text1{padding-right: 440px;}

.l2_block3_text1{ padding-bottom: 15px;}

.underpatment_block3_img1{ position: absolute;  right: 0px; top: -17px;
    height: 79%;
    width: auto;}

.l2_block3_text1 p{ font-size: 20px; color: #fff;}

.l2_block3_text1 p span{ color: #ffde00;}

.l2_block3_text3{  background:  rgba(228, 238, 249,0.4); padding: 6px; border-radius: 12px;   box-shadow: 0 0 10px #24699f; position: relative;}

.l2_block3_text3_con{ background:#ffffff; border-radius: 10px; padding:26px 30px;}

.l2_block3_text3_con p{ font-size: 24px; color: #282828; font-weight: 500;}

.l2_block4{ background: url('https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage2/underpatment_block4_main.webp') no-repeat right bottom; }
.l2_block4_main{background: url('https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage3/underpatment_block4_bg1111.png') no-repeat left 180px top -23px; }

.l2_block4_BG{background: url('https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage2/rd_landing_pg2_block4_bg2_new.webp') no-repeat left bottom;  padding-bottom: 80px;}

.l2_block4_con1{ padding: 40px 0; padding-left: 870px;}

.l2_block4 h2{ margin: 0; padding: 0 0 20px 0; font-size: 50px;      line-height: 50px; color: #0e0e0e;}

.l2_block4 ul{ margin: 0; padding: 0;}
.l2_block4 li{ list-style: none; font-size: 30px; color: #222222; background: url('https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage2/underpatment_block4_icon1.webp') no-repeat left 12px; margin-bottom: 10px; padding-left: 34px;}

.l2_block4_con2{   background:#fff url('https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage2/underpatment_block4_bg3.webp') no-repeat right bottom;  padding: 40px; box-shadow: 0 0 10px #9fa0a1; border-radius: 15px; padding-right: 830px;}

.l2_block4_con2 h2{ font-size: 40px; padding-bottom: 20px;}
.l2_block4_con2 li{ font-size: 22px;}

.l2_block4_con2_sub{  background:url('https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage2/underpatment_block4_subBG.webp') no-repeat left top; margin-top: 20px; border: solid 6px #ededed; border-radius:15px; padding:10px 20px; background-size: cover; }

.l2_block4_con2_sub label{ display: block;  margin: 12px 0; font-size: 20px; color: #fff; background: url('https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage2/underpatment_block4_icon2.webp') no-repeat 0 2px; padding-left: 50px; text-shadow: 0 0 2px #5eb8ff;}

.l2_block4_con3{ padding-left: 920px; padding-top: 35px;}

.l2_block4_con3 h2{ font-size: 40px; padding-bottom: 15px;}
.l2_block4_con3 li{ font-size: 22px;}


.l2_block4_con3 h3{ font-size: 24px; color: #000000; padding-bottom: 20px; font-weight: 600;}

.l2_block4_con3 li label{display: inline-block;  background: url('https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage2/underpatment_block4_icon2.webp') no-repeat 0 1px; padding-left: 40px; padding-right: 30px;  margin: 5px 0; font-size: 18px;}

.LandingPage2body{ background:   #ecf2f8 url('https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage2/underpatment_bottom_bg_main.webp') no-repeat right bottom; }

.l2_block5{  background: url('https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage2/underpatment_block5_bottom_BG.webp') no-repeat right bottom;margin-top: -40px;}

.l2_block5_body{background: rgba(206, 222, 228, 0.5); padding: 8px; border-radius: 10px;}

.l2_block5_body_BG{ background: url('https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage2/underpatment_block5_BG_main.webp') no-repeat 0 0;  background-size: cover; border-radius: 8px;  } 

.l2_block5_body_BG2{ background: url('https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage2/underpatment_block5_BG1.webp') no-repeat  left bottom; border-radius: 8px; padding: 36px; background-blend-mode: multiply;} 

.l2_block5_text1{  background: #fff; border-radius: 8px; background-size: cover;     box-shadow: 0 0 10px #24699f; padding: 25px; display: flex; flex-wrap: wrap; justify-content: space-between;     align-items: center;}

.l2_block5_text1_textcon{ width: 54%;}
.l2_block5_text1_img{ width: 44%;}
.l2_block5_text1_img img{width: 100%; border: solid 6px #fff; border-radius: 15px; box-shadow: 0 0 10px #8d8d8d; }

.l2_block5_text1_textcon h2{ margin: 0; padding: 0; font-size: 50px; background: rgb(59,59,59);
    background: linear-gradient(180deg, rgba(59,59,59,1) 0%, rgba(19,20,20,1) 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text; line-height: 58px;}
.l2_block5_text1_textcon p{ margin: 0; padding: 0; font-size: 18px; color: #000000;}
.l2_block5_text1_textcon p span{ color: #0180f6;}

.l2_block5_text2{ padding-left: 48%;    }
.l2_block5_text2_body{ position: relative;}
.l2_block5_text2_body::after{ content: ''; display: block; width: 5px;      height: calc(100% - 34px);  background: #94c7fe; position: absolute;     left: -4px; top: 34px;}

.l2_block5_text2_block{ position: relative; margin-top: 22px; padding-left: 25px;}

.l2_block5_text2_block::before{  content: ''; display: block; width:39px; height: 39px;  background: url('https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage2/underpatment_block5_icon1.webp') no-repeat  left top;     position: absolute;
    left: -21px;  top: 14px ; z-index: 9; }

 .l2_block5_text2_block h3{ display: block;  font-size: 50px; line-height: 60px; background: rgb(252,252,252);
    background: linear-gradient(180deg, rgba(252,252,252,1) 0%, rgba(222,223,224,1) 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;  filter: drop-shadow(2px 3px 4px rgba(0,0,0,0.6));}  

 .l2_block5_text2_block p{ margin: 0; padding: 15px 0 0 0; font-size: 18px; color: #fff; line-height: 25px;}


 .l2_block5_bottom_text{ padding: 20px 720px 30px 0; min-height: 250px;}

 .l2_block5_bottom_text h2{ font-size: 50px; color: #011f40; margin-top: 15px;}

 .l2_block5_bottom_text p{ font-size: 30px; line-height: 34px; color: #000000;}
 .l2_block5_bottom_text p span{ color: #01b5ff;}

.l2_block7_con{background: url('https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage2/underpatment_bottom_block1.webp') no-repeat  left bottom; padding: 60px 0; padding-left: 660px; background-blend-mode: multiply;  min-height: 270px; margin-top: 20px;}
.l2_block7_con h2 { margin: 0;  padding: 0 0 0 24px; position: relative; font-size: 70px; text-transform: uppercase; line-height: 70px;}

.l2_block7_con h2 strong{background: linear-gradient(180deg, rgba(59,59,59,1) 0%, rgba(19,20,20,1) 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text; }   

.l2_block7_con h2 span{background: rgb(0,209,255);
        background: linear-gradient(180deg, rgba(0,209,255,1) 0%, rgba(1,177,255,1) 100%);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text; }   

.l2_block7_con h2::before{ content: ''; width: 8px; height: 100%; background: #00d2ff; position: absolute; left: 0; top: 0;}


.l2_block6_wrapper{background: #fff;
    padding: 20px 40px;
    box-shadow: 0 0 10px #9fa0a1;
    border-radius: 15px; }

 .l2_block6_block{background: url('https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage2/underpatment_block7_BG1.webp') no-repeat; background-size: cover; border-radius: 5px; display: flex; margin: 20px 0; align-items: center; padding: 40px; justify-content: space-between; }   
 .l2_block6_block:nth-of-type(even){background: url('https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage2/underpatment_block7_BG2.webp') no-repeat; background-size: cover; border-radius: 5px; flex-direction: row-reverse; }   

 .l2_block6_block .l2_block6_block_img img{    border: solid 6px #fff;
    border-radius: 15px;
    box-shadow: 0 0 10px #333; width: 100%;}

 .l2_block6_block .l2_block6_block_img { width: 50%;}  
 .l2_block6_block_text{ width: 46%;}

 .l2_block6_block_text h2{ margin: 0; padding: 0; font-size: 40px; background: rgb(252,252,252);
    background: linear-gradient(180deg, rgba(252,252,252,1) 0%, rgba(222,223,224,1) 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    filter: drop-shadow(2px 3px 4px rgba(0,0,0,0.6));}

.l2_block6_block_text p{margin: 0;
        padding: 0px 0 0 0;
        font-size: 22px;
        color: #fff;}   
.l2_block6_block_text p span{ color: #fffc00;}  
.l2_block6_block:nth-of-type(even) .l2_block6_block_text p span{ color: #00b3fe;}   

.l2_block6_block_text button{ width: auto;  max-width: 100%;  text-align: center; line-height: 35px; padding: 10px 15px; border: none; cursor: pointer;  background: rgb(64,64,64);
    background: linear-gradient(180deg, rgba(64,64,64,1) 0%, rgba(7,7,7,1) 100%); border-radius: 10px;  text-transform: capitalize; font-weight: 500; display: block; margin-top: 18px; font-size: 30px; color: #fff; cursor: pointer; text-decoration: none;}
  
 .l2_block6_block:nth-of-type(even) .l2_block6_block_text button{background: rgb(0,209,255);
    background: linear-gradient(180deg, rgba(0,209,255,1) 0%, rgba(1,176,255,1) 100%);}
.l2form_style .state svg{
    color: #fff;
}

@media only screen and (max-width: 1820.98px) {
.l2Wrapper{ width: 96%; }
 .l2_top_banner_left{ width: 70%;}
.l2_top_banner_right{ width: 28%;}
.l2form_box .l2form_style_heading h2 br{ display: none;}
/* 
.l2_block4_main{ background-position: -50px top;} */
}

@media only screen and (max-width: 1760.98px) {
.l2_top_banner_arrowCon h4{ font-size: 35px;}
.l2form_box .l2form_style_heading h2{font-size: 28px;  line-height: 30px;}
.l2_top_banner_arrowCon{ height: 160px;}

.l2_block3_con .l2_block3_text1 {
    padding-right: 600px;
}

.l2_block4_con2 h2{ width: 150%;}

.l2_block5_body_BG2{ background-position: -90px bottom;}

.l2_block4 ul{ width: 100%;}

.underpatment_block3_img1{top: -19px;}
.l2_block4 h2 {font-size: 42px; line-height: 50px;}
}
@media only screen and (max-width: 1660.98px) {
.l2_block4 ul {
    width: auto;
}
.l2_block4_con2 li:nth-of-type(1){ width: 140%;}
.l2_block4_con2 li:nth-of-type(2){ width: 120%;}

.l2form_style .MuiFormLabel-root{ font-size: 12px; line-height: 12px;}
.l2form_style .formfilddec textarea::placeholder{ font-size: 12px; line-height: 14px;}

}


@media only screen and (max-width: 1640.98px) {

  .l2_block4_con2{ background-position: 120% bottom;}
  .l2_block6_wrapper{    padding: 10px 20px;}

  .l2_block6_block{ margin: 10px 0;}

  .l2_block6_block{ padding: 15px;}

  .l2_block6_block_text h2{ font-size: 35px; line-height: 38px;}
  .l2_block6_block_text p{ font-size: 18px; margin: 0; padding: 10px 0 0 0;}

   .l2_block7_con h2 { 
        font-size: 56px; 
        line-height: 60px;
    }


    .l2_block5_bottom_text h2{ font-size: 38px; line-height: 50px;}

    .l2_block5_bottom_text p {
        font-size: 26px;
        line-height: 34px; 
    }

    .l2_block4_BG{ background-position: -130px bottom;}
    .l2_block4_con3 {
        padding-left: 800px; 
    }

    .l2_block4 h2{ font-size: 38px; line-height: 40px;}  
    .l2_block4 li{ font-size: 24px;} 

 

 
.l2_top_banner_text h2 {    font-size: 57px;   line-height: 45px;  }
.l2_top_banner_textCon label p{ font-size: 32px;}
.l2form_box .l2form_style_heading h2 {
    font-size: 18px;
    line-height: 20px;
}
.l2form_box .l2form_style_heading{ padding: 14px 5px;}
.l2_block2_text h2{ font-size: 40px;     background-size: 124px;}

.l2_top_bannerBG{ background-size: 30%;}

.l2_block5_text1_textcon h2{ font-size: 38px; line-height: 48px; margin-bottom: 15px;}
.l2_block5_text2_block h3{ font-size: 38px; line-height: 48px; margin-bottom: 0px;}

.l2_block5_text2_block::before{ top: 6px;}

}

@media only screen and (max-width: 1605.98px){
    .underpatment_block3_img1{
        height: 74%;
    }
}

@media only screen and (max-width: 1600.98px) {
 .l2_block3_con{background: rgb(1,70,241);
    background: linear-gradient(112deg, rgba(1,70,241,1) 0%, rgba(1,97,249,1) 25%, rgba(5,157,251,1) 74%, rgba(0,138,250,1) 100%);}
    .l2_block3_con .l2_block3_text1 {
        padding-right: 540px;
    }

    .l2_block2_text h2 { 
        width: 110%;
        margin-left: -10%;
    }
}

@media only screen and (max-width: 1580.98px) {

    .l2_block2_bg{ padding-top: 0;}

  .l2_block5_body_BG2{ background-position: -190px bottom;}
 

 .l2_block2{ background-position: 65px top;}
 
.l2_block4_con3 h2{ margin-left: -796px;}
.l2_block4_con3 h3{ margin-left: -796px;}
}

@media only screen and (max-width: 1540.98px) {
  .l2_top_banner_arrowCon h4 {  font-size: 30px;     padding: 34px 30px; }
  .l2_top_banner_arrowCon {
    height: 150px;
}

.l2_block5_bottom_text p {
    font-size: 24px;
    line-height: 30px; 
}

.l2_block4_con2 li:nth-of-type(1) {
    width: 150%;
}
.l2_block4_con2 li:nth-of-type(2) {
    width: 140%;
}

}

@media only screen and (max-width: 1500.98px) {


    .l2_block4_con2{ padding-right: 600px;}

    .l2_block2_text h2 {
        width: 125%;
        margin-left: -25%;
    }

    .l2_block7_con{  padding-left: 600px;}
    .l2_block4_con2{ background-position: 150% bottom;}
 
    .l2_block4_con2 li:nth-of-type(1) {
        width: 130%;
    }
    .l2_block4_con2 li:nth-of-type(2) {
        width: 120%;
    }
   
 .l2_top_banner_text{ width: 70%;}
 .l2_top_banner_text h2 {
    font-size: 50px;
    line-height: 40px;
}
.l2_top_banner_textCon label p {
    font-size: 26px;
    line-height: 34px;
}

.l2_block2_text h2 {
    font-size: 38px;
    background-size: 96px;
    min-height: inherit;
}

.l2_block4_main {
    background-position: 65px top;
}
.l2_block4_con1 {
    padding-left: 715px;
}

}

@media only screen and (max-width: 1480.98px) {
    .l2_block4_con2 h2 {
        width: auto;
    }
    .l2_block4_con2  ul  {
        width: auto;
    }

    .l2_block4_con2{ padding-right: 550px;}
}

@media only screen and (max-width: 1440.98px) {

    .l2form_style .formfild {
        margin-bottom: 4px;
    }

    .l2form_style .formWrapperClass {
        padding: 5px 5px 5px 5px;
    }


    .l2_block5_body_BG2{ padding: 15px;}
    .l2_top_bannerBG{ background-size: 530px;}
    .l2_top_banner_text h2 {
        font-size: 40px;
        line-height: 30px;
    }

    .l2_top_banner_text h3{ font-size: 20px;}

    .l2_block3_con{ padding: 20px;}

    /* .l2_block4_con3 h2{ margin-left: -400px;}
.l2_block4_con3 h3{ margin-left: -400px;} */


}

@media only screen and (max-width: 1420.98px) {
    /* .l2form_box .l2form_style_heading h2 {
        font-size: 22px;
        line-height: 24px;
    }
     */
    .l2_block7_con h2 {
        font-size: 46px;
        line-height: 44px;
    }
}

@media only screen and (max-width: 1400.98px) {
    

    /* .l2_block4_con3 h2{ margin-left: -800px;}
    .l2_block4_con3 h3{ margin-left: -800px;} */

    /* .l2_block3_con .l2_block3_text1 {
        padding-right: 310px;
    } */
    .l2_block3_text1 p{font-size: 18px;    line-height: 24px;}
    .l2_top_banner_arrowCon {
        height: 140px;
    }
    .underpatment_block3_img1 {
        top: -18px;
    }

    .l2_block2_text h2{ font-size: 35px; }

    .l2_block2_text h3{ font-size: 30px;}
}

@media only screen and (max-width: 1380.98px) {
    .l2_block4_con3 li:nth-of-type(1){ margin-left: -150px;}
}
@media only screen and (max-width: 1360.98px) {
 
.l2_block2_text h3{ padding: 15px 0;}
.l2_block2_bg {
    padding-left: 670px;
    padding-top: 0;
    padding-bottom: 25px;
}
.l2_block2_text p{ font-size: 22px;}

.l2_block4_con1 {
    padding-left: 650px;
}
.l2_block4_main {
    background-position: left 28px top 28px;
}
.l2_block4_con2 {
    background-position: 181% bottom;
}
.l2_block4_con2 h2 {
    width: 150%;
}

.l2_block5_body_BG2 {
    background-position: -270px bottom;
}

}

@media only screen and (max-width: 1340.98px) {

    .l2_block4_con3 li:nth-of-type(1){ margin-left: -800px;}
 
.l2_top_banner_arrowCon {  height: 140px;}
/* .l2_top_banner_arrowCon h4 {font-size: 30px;} */

}
@media only screen and (max-width: 1320.98px) {
    .l2_top_banner_arrowCon h4{ padding: 9px 30px;}
    .l2_top_banner_arrowCon {
        height: 130px;
    }

    .l2_block4_con2 li:nth-of-type(1) {
        width: 140%;
    }
}

@media only screen and (max-width: 1300.98px) {
    .l2_block2_text h2 {
        font-size: 34px;
    }
}

 @media only screen and (max-width: 1280.98px) {
    .l2_block2_text p { 
        font-size: 22px;
        line-height: 30px;}
        
  .l2_block4_con3 li:nth-of-type(2){ margin-left: -796px;}
  .l2_block7_con h2 {
    font-size: 42px;
    line-height: 42px;
}

.l2_block2_text h2 {
    width: 130%;
    margin-left: -30%;
 
    font-size: 32px;
    line-height: 36px; background-size: 82px;
}

.l2_block2_text h3 {
    font-size: 26px;
}
.l2_block4_con2 li:nth-of-type(1) {
    width: 150%;
}
}

@media only screen and (max-width: 1210.98px){
    .underpatment_block3_img1 {
        top: -19px;
    }

    .l2_top_banner_text h3 {
        font-size: 18px; line-height: 20px;
    }
}

 
@media only screen and (max-width: 1199.98px) {

    .newscrollbtn{ font-size: 26px;     line-height: 28px;} 

    /* .l2form_style .formfild input{ height: 40px;}
    .l2form_style .MuiFormLabel-root {
        font-size: 12px;
        line-height: 14px;
    } */
 
    
    
    .l2_block4_con2 li:nth-of-type(1) {
        width: auto;
    }

    .l2_block4_con2 li:nth-of-type(2) {
        width: auto;
    }

    .l2_block6_block_text h2{ font-size: 26px; line-height: 35px;}
    .l2_block6_block_text p{ font-size: 18px; }

    .l2_block6_block{ display: block; text-align: center;}

    .l2_block6_block_text button{ margin-left: auto; margin-right: auto; font-size: 26px;   line-height: 28px;   }

    .l2_block6_block .l2_block6_block_img {
        width: auto;
    }
    .l2_block6_block_text{ width: auto; margin-top: 15px;}
  

    .l2_block7_con { margin-top: 25px;
        padding: 0;
        display: block;
        background-position: center bottom;
        padding-bottom: 290px;
        min-height: inherit;
    }

    .l2_block7_con h2{ text-align: center; padding: 0;}
    .l2_block7_con h2::before{ display: none;}

    .l2_block5_bottom_text h2{ font-size: 26px; line-height: 30px; padding-bottom: 12px;}

    .l2_block5_bottom_text p {
        font-size: 18px;
        line-height: 25px; 
    }
    .l2_block5_bottom_text {
        padding: 15px;
        min-height: inherit;
    }

   
    .l2_block5{ background-position: center bottom;      padding-bottom: 330px;}

    .l2_block4_con3 li label{ font-size: 18px;}

    .l2_block5_body_BG2{ background: none;}

    .l2_block5_text2 {
        padding-left: 0;
    }

    .l2_block5_text2_body::after{ display: none;}
    .l2_block5_text2_block::before{ display: none;}
    .l2_block5_text2_block{ padding-left: 0;}

    .l2_block5_text2_block h3{ font-size: 26px; line-height: 30px;margin-bottom: 13px;  }
    .l2_block5_text2_block p{ font-size: 18px; padding: 0;}

    .l2_block5{ margin-top: 0px;}

    .l2_block5_text1{ display: block;}

    .l2_block5_text1_textcon {
        width: auto;
    }

    .l2_block5_text1_img {
        width: auto;
        margin-top: 15px;
    }

    .l2_block5_text1_textcon h2{ font-size: 26px; line-height: 30px;}

    .l2_block5_text1_textcon p{ font-size: 18px;line-height: 25px;}

    .l2_block5_text1{ padding: 15px;}

    .l2_block4_con2_sub{ padding: 15px;}
    .l2Wrapper {
        width: 93%;
    }
    .l2_block4_con3 {
        padding: 0 15px;
        margin-top: 30px;
    }
    .l2_block4_BG{ background: none; padding-bottom: 20px;}
    
    .l2_block4_con3 h2 {
        margin-left: 0;
    }


    .l2_block4_con3 h3 {
        margin-left: 0;
    }
    .l2_block4_con3 li:nth-of-type(1){ margin-left: 0px;}
    .l2_block4_con3 li:nth-of-type(2){ margin-left: 0px;}
 

 

    .l2_block4_con2{ background-image: none; padding: 15px;}

    .l2_block4_con2 h2 {
        width: auto;
    }

    .l2_block4_main {
        background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage3/underpatment_block4_bg1199.webp);
        background-position: center top;
        background-size: 103%;
        padding-top: 69.5%;
    }


    .l2_block4_con1 {
        padding: 0 15px;
        padding-bottom: 15px;
}
    

    .l2_block4 h2 { 
        font-size: 26px;
        line-height: 30px; 
        padding: 0 0 12px 0;
    }

    .l2_block4_con3 h3{ font-size: 18px;}

    .l2_block4_con2 h2 { 
        padding-bottom: 12px;
    }

    .l2_block4 li {
        font-size: 18px;
        background-position: left 6px;
    }

    .l2_block4_con2_sub label{ font-size: 18px;     padding-left: 40px;}


    .l2Wrapper{ display: block;}
    .l2_top_bannerBG{ background: none;}
    .l2_top_banner_left {
        width: auto;
    }
    .l2_top_banner_text {
        width: auto; text-align: center;
    }
    .l2_top_banner_right {
        width: auto;
        margin: 10px 0 0 0;
    }
    .l2form_box .l2form_style_heading h2 {
        font-size: 26px;
        line-height: 30px;
    }

    /* .l2_top_banner_arrowCon{
        padding: 20px;
        color: #fcaf2a;
        background: #fcaf2a;
        background: linear-gradient(180deg,rgba(252,175,42,1) 0%,rgba(253,104,31,1) 100%);
        margin: 20px 0; height: auto;
    } */


    .l2_top_banner_arrowCon{
        padding: 20px;
       
        background: rgb(87,254,246);
        background: linear-gradient(180deg, rgba(87,254,246,1) 0%, rgba(1,197,254,1) 100%);
        margin: 20px 0; height: auto;
    }
    .l2_top_banner_arrowCon h4 {
        font-size: 28px; line-height: 30px; padding: 0; margin: 0; text-align: center; display: block;
    }

    .l2_block2{ background: none;}
    .l2_block2_bg {
        padding-left: 0;
        padding-top: 25px;
        padding-bottom: 25px;
    }
    .l2_block2_text h2{ background: none; padding: 0;}

    .l2_top_banner_text h2 {
        font-size: 30px;
        line-height: 30px;     padding: 20px 10px;
    }

    .l2_top_banner_textCon label{ padding: 15px;}

    .l2_top_banner_textCon label p {
        font-size: 22px;
        line-height: 30px;
    }

    .l2_block2_text h2 {
        font-size: 26px;
        line-height: 35px; width: auto; margin: 0;
    }
    .l2_block2_text h3 {
        font-size: 22px;
        line-height: 30px;
    }
    .l2_block2_text p {
        font-size: 18px;
        line-height: 25px;
    }

    .l2_block3_text3_con{ padding: 15px;}
    .underpatment_block3_img1{ display: none;}

    .l2_block3_con .l2_block3_text1 {
        padding-bottom: 25px;
        padding-left: 15px;
        padding-top: 25px;
        padding-right: 15px;
    }

    .l2_block3_text3_con p{ font-size: 18px;}
    .l2_block3_text1 p{ font-size: 18px;}

    .l2_logowrapper img{ margin-left: auto; margin-right: auto; display: block;}
}

@media only screen and (max-width:767.98px) {

    .l2_block5{ background-size: 100%;     padding-bottom:45%;} 
}

@media only screen and (max-width:600.98px) {
    .l2_block7_con { 
        padding-bottom: 50%; 
        background-size: 100%;
    }
    .l2_block7_con h2 {
        font-size: 32px;
        line-height: 32px;
    }
    /* .l2_block6_block_text button{  min-width: 200px; max-width: 90%; } */

}

@media only screen and (max-width:480.98px){
    .l2_block4 li {
     padding-left: 30px;
    }
    .l2_block4_main{
        padding-top: 60.5%;
    }
    .l2_block4_main {
        padding-top: 74.5%;
    }
    .l2_block5_text2_block h3{font-size: 24px;}
}
