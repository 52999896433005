.lpage_banner_titlewrapper h1 {
  font-size: 60px;
  line-height: 60px;
  font-weight: var(--main-font-Bold);
  text-transform: uppercase;
  background: linear-gradient(
    180deg,
    rgba(35, 192, 231, 1) 0%,
    rgba(36, 160, 201, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(0 1px 0 rgba(0, 0, 0, 0.4));
}
.lpage_banner_titlewrapper {
  margin-bottom: 35px;
}
.lpage_banner_titlewrapper h1 span {
  background: linear-gradient(
    180deg,
    rgba(0, 112, 175, 1) 0%,
    rgba(30, 60, 114, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.landing-container {
  width: 1795px;
  display: block;
  margin: 0 auto;
}
.lpage_banner_sub_titlewrapper {
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage/images/l_page1_banner_bg2.webp);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: cover;
  padding: 35px;
  width: calc(915px - 70px);
  margin-bottom: 35px;
}
.lpage_banner_sub_titlewrapper h3 {
  text-transform: uppercase;
  color: #fff;
  font-size: 32px;
  line-height: 34px;
  font-weight: var(--main-font-SemiBold);
}
.lpage_banner_textwrapper {
  width: 740px;
}
.lpage_banner_textwrapper p {
  font-weight: var(--main-font-Medium);
  font-size: 28px;
  line-height: 36px;
  color: #000000;
}
.lpage_banner_textwrapper p span {
  color: #0070af;
}
.lpage_banner_list_item {
  background: rgb(1, 51, 103);
  background: linear-gradient(
    90deg,
    rgba(1, 51, 103, 1) 0%,
    rgba(0, 70, 143, 1) 100%
  );
  padding: 8px 20px;
  width: calc(25% - 10px);
  margin: 5px;
  padding-left: 109px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.lpage_banner_list_wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  flex-wrap: wrap;
}
.lpage_banner_list_item h2 {
  font-size: 18px;
  color: #fff;
  font-weight: var(--main-font-Medium);
  position: relative;
}
.lpage_banner_list_number {
  font-size: 30px;
  color: #fff;
  background: #00a8ff;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lpage_banner_list_number::before {
  content: "";
  height: 100%;
  width: 40px;
  position: absolute;
  left: 80px;
  top: 7px;
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage/images/l_page1_banner_shadow2.png);
  background-repeat: no-repeat;
  background-size: 100%;
}
.lpage_arrow_wrapper {
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage/images/l_page1_banner_arrow.webp);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 60px 60px 60px 40px;
  position: relative;
}
.lpage_arrow_wrapper::after {
  content: "";
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage/images/l_page1_banner_arrow_shadow.png);
  background-repeat: no-repeat;
  width: 300px;
  height: 40px;
  position: absolute;
  left: 8px;
  top: -1px;
  
}
.lpage_arrow_wrapper h2 {
  color: #fff;
  text-transform: uppercase;
  font-size: 26px;
  line-height: 31px;
  font-weight: var(--main-font-Bold);
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
}
.landing-banner-left {
  width: 68%;
}
.landing-banner-right {
  width: calc(32% - 100px);
  margin-left: 100px;
}
.landing-col-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.landing_page1_bannerblock {
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage/images/l_page1_bannerimage.webp),
    url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage/images/l_page1_banner_bg.webp);
  background-repeat: no-repeat;
  background-size: auto, cover;
  padding: 45px 0;
  background-position: right 440px bottom, right top;
}
.lpage_logowrapper {
  margin-bottom: 50px;
}
.lpage_logowrapper img {
  max-width: 100%;
  display: block;
}

/* form block css  */

.formwrapper {
  background: rgb(35, 203, 230);
  background: linear-gradient(
    180deg,
    rgba(35, 203, 230, 1) 0%,
    rgba(2, 173, 200, 1) 100%
  );
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 8px;
}
.form_title_wrapper {
  background: rgb(0, 53, 107);
  background: linear-gradient(
    180deg,
    rgba(0, 53, 107, 1) 0%,
    rgba(1, 35, 72, 1) 100%
  );
  padding: 26px 12px;
  text-align: center;
  border-radius: 5px;
}
.form_title_wrapper h2 span {
  font-size: 30px;
  line-height: 32px;
  text-transform: uppercase;
  display: block;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(182, 183, 184, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.form_style {
}
.form_style .MuiFormControl-root {
  width: 100%;
  position: relative;
}
.form_style .formfild {
  margin-bottom: 12px;
}
.form_style .MuiInputBase-root {
  border-radius: 0;
}
.form_style .MuiOutlinedInput-notchedOutline {
  border: 1px solid transparent !important;
}
.form_style .formfild input {
  background-color: #fff;
}
.form_style .MuiFormLabel-root {
  font-weight: var(--main-font-Bold);
  color: #303232 !important;
  top: -3px;
  font-size: 18px;
}
.form_style .MuiFormLabel-filled {
  display: none;
}
.form_style label.Mui-focused {
  display: none;
}
.form_style legend {
  display: none;
}
.form_style fieldset {
  top: 0 !important;
}
.form_style .form_radio .MuiFormGroup-root {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.form_style .form_radio .MuiFormControlLabel-root {
  color: #fff;
}
.form_style .form_radio .MuiFormControlLabel-root .MuiSvgIcon-root path {
  display: none;
}
.form_style .css-hyxlzm .MuiSvgIcon-root:nth-child(1) {
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #4b5253;
}
.form_style .css-hyxlzm .MuiSvgIcon-root:nth-child(2) {
  height: 20px;
  width: 20px;
  background: rgb(135, 231, 113);
  background: linear-gradient(
    180deg,
    rgba(135, 231, 113, 1) 0%,
    rgba(38, 188, 4, 1) 50%,
    rgba(21, 106, 2, 1) 100%
  );
  border-radius: 50%;
  border: 3px solid #ffffff;
  outline: 1px solid #4b5253;
}
.submitbtnsection button {
  background: rgb(65, 65, 65);
  background: linear-gradient(
    180deg,
    rgba(65, 65, 65, 1) 0%,
    rgba(3, 3, 3, 1) 100%
  );
  border: 1px solid #00dcff;
  border-radius: 5px;
  font-size: 40px;
  line-height: 40px;
  color: #fff;
  font-weight: var(--main-font-SemiBold);
  text-transform: uppercase;
  padding: 20px;
  width: calc(100%);
}
.form_style .form_radio,
.form_style .form_text {
  margin-bottom: 15px;
}
.form_text .MuiTypography-root {
  font-size: 18px;
}
.form_text p {
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  font-weight: var(--main-font-SemiBold);
  text-shadow: 1px 1px 1px #0796ad;
}
.form_style .formWrapperClass {
  padding: 15px 15px 5px 15px;
}

.form_style .MuiFormHelperText-root.Mui-error,
.form_style .MuiFormHelperText-root,
.l2form_style .MuiFormHelperText-root.Mui-error,
.l4form_style .MuiFormHelperText-root.Mui-error,
.new_l4form_style .MuiFormHelperText-root.Mui-error,
.new_l4form_style .MuiFormHelperText-root,
.l2form_style .MuiFormHelperText-contained,
.landingFour .MuiFormHelperText-contained {
  position: absolute;
  left: -209px;
  top: 50%;
  transform: translateY(-50%);
  background: #ffffff;
  padding: 9px 17px;
  font-size: 14px;
  border-radius: 8px;
  color: #f00 !important;
  border: 1px solid #eb0404;
}
.form_style .MuiFormHelperText-root.Mui-error::after,
.form_style .MuiFormHelperText-root::after,
.l2form_style .MuiFormHelperText-root.Mui-error::after,
.l4form_style .MuiFormHelperText-root.Mui-error::after,
.new_l4form_style .MuiFormHelperText-root.Mui-error::after,
.new_l4form_style .MuiFormHelperText-root::after,
.l2form_style .MuiFormHelperText-contained:after,
.landingFour .MuiFormHelperText-contained::after {
  content: "";
  position: absolute;
  right: -12px;
  top: 50%;
  transform: translateY(-50%);
  border-width: 36px;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
  border-left: 13px solid white;
}
.form_style .MuiFormHelperText-root.Mui-error::before,
.form_style .MuiFormHelperText-root::before,
.l2form_style .MuiFormHelperText-root.Mui-error::before,
.l4form_style .MuiFormHelperText-root.Mui-error::before,
.l2form_style .MuiFormHelperText-contained::before,
.landingFour .MuiFormHelperText-contained::before {
  content: "";
  position: absolute;
  right: -14px;
  top: 50%;
  transform: translateY(-50%);
  border-width: 36px;
  width: 0;
  height: 0;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  border-left: 14px solid #eb0404;
}

.landing_page1_block2_colcontainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.title_wrapper h2 span {
  font-size: 40px;
  line-height: 49px;
  font-weight: var(--main-font-Bold);
  background: linear-gradient(
    180deg,
    rgba(0, 112, 175, 1) 0%,
    rgba(30, 60, 114, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
}
.title_wrapper h2 {
  margin-bottom: 20px;
}
.text_wrapper p {
  font-size: 18px;
  line-height: 24px;
  font-weight: var(--main-font-SemiBold);
  margin-bottom: 20px;
}
.text_wrapper p span {
  color: #05529a;
}
.text_wrapper p:last-child {
  margin: 0;
}
.text_wrapper p:first-child {
  margin-bottom: 20px;
}
.landing_page1_block2_subtitle {
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage/images/l_page1_image2.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 15px 20px;
  background-size: 100% 100%;
}
.landing_page1_block2_subtitle h3 {
  font-size: 25px;
  line-height: 32px;
  font-weight: var(--main-font-SemiBold);
  color: #fff;
  text-transform: uppercase;
  width: 900px;
}
.landing_page1_block2 {
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage/images/l_page1_image1.png);
  background-repeat: no-repeat;
  background-position: right 750px top;
  padding-top: 50px;
  margin-bottom: 10px;
}
.landing_page1_block2_left {
  width: 61%;
}
.landing_page1_block2_video_wrapper {
  background: #013367;
  padding: 10px;
  position: relative;
  padding-bottom: 53.2%;
  border: 10px solid #013367;
}
.landing_page1_block2_video_wrapper iframe {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.landing_page1_block2_right {
  width: 39%;
}
.landing_page1_block2 .text_wrapper {
  width: 693px;
}
.landing_page1_block2 .title_wrapper {
  width: 888px;
}
.text_wrapper h2 {
  font-size: 40px;
  line-height: 45px;
  font-weight: var(--main-font-Bold);
  margin-bottom: 15px;
}

.landing_page1_block3 .text_wrapper h2,
.landing_page1_block3 .text_wrapper p {
  color: #fff;
  text-shadow: 1px 1px 4px rgb(0 0 0 / 30%);
}
.landing_page1_block3 .text_wrapper p span {
  color: #fff000;
}
.landing_page1_block3 .text_wrapper {
  position: relative;
  padding-left: 20px;
}
.landing_page1_block3 .text_wrapper::before {
  content: "";
  height: 98%;
  width: 5px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: rgb(1, 51, 103);
  background: linear-gradient(
    180deg,
    rgba(1, 51, 103, 1) 0%,
    rgba(1, 51, 103, 1) 15%,
    rgba(255, 255, 255, 1) 15%,
    rgba(255, 255, 255, 1) 100%
  );
}
.landing_page1_block3_subwrapper {
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage/images/l_page1_bg2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 30px;
  background-position: center center;
}
.landing_page1_block3_left {
  width: calc(47% - 40px);
  margin-right: 40px;
}
.landing_page1_block3_right {
  width: 53%;
}
.landing_page1_block3_imgwrapper {
  padding: 13px;
  background: #36d5f886;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.082);
  height: calc(100%);
}
.landing_page1_block3_imgwrapper img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title_main_wrapper {
  background-color: #013367;
  padding: 9px;
  margin-bottom: 20px;
}
.title_sub_wrapper {
  background: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage/images/l_page1_titlebg2.png),
    linear-gradient(180deg, rgba(0, 186, 255, 1) 0%, rgba(1, 97, 151, 1) 100%);
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  background-position: right -3px bottom, center;
  padding: 9px 15px;
  border: 1px solid #0086c2;
  align-items: center;
  background-size: auto 100%;
}
.title_main_wrapper h2 {
  color: #fff;
  text-transform: uppercase;
  font-weight: var(--main-font-SemiBold);
  font-size: 40px;
  line-height: 50px;
  margin: 0;
  background: linear-gradient(
    180deg,
    rgb(255, 255, 255) 0%,
    rgb(214, 214, 214) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(0 1px 5px rgba(0, 0, 0, 0.2));
}
.title_main_wrapper img {
  margin-left: 20px;
  display: block;
  max-width: 100%;
}

.landing_page1_block4 {
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage/images/l_page1_image4.webp);
  background-repeat: no-repeat;
  background-position: right bottom  -90px;
  padding: 50px 0;
}
.landing_page1_block4_subwrapper {
  width: 850px;
}
.landing_page1_block4 .text_wrapper p {
  font-size: 24px;
  font-weight: var(--main-font-Medium);
  line-height: 31px;
}

.landing_page1_block5_subwrapper {
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage/images/l_page1_bg3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  min-height: 230px;
  position: relative;
  padding-left: 510px;
}
.landing_page1_block5_subwrapper img {
  position: absolute;
  left: 100px;
  bottom: 0;
  display: block;
  max-width: 100%;
}
.landing_page1_block5_subwrapper h3 {
  font-size: 46px;
  line-height: 55px;
  font-weight: var(--main-font-Bold);
  text-transform: uppercase;
  background: rgb(161, 249, 255);
  background: linear-gradient(
    180deg,
    rgba(161, 249, 255, 1) 0%,
    rgba(0, 153, 221, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(0 1px 0 rgba(0, 0, 0, 0.4));
}
.landing_page1_block5_subwrapper h3 span {
  font-size: 74px;
  line-height: 78px;
}
.landing_page1_block5_subwrapper p {
  font-size: 30px;
  font-weight: var(--main-font-Medium);
  color: #fff;
}

.landing_page1_block6 {
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage/images/l_page1_image6.webp);
  background-repeat: no-repeat;
  background-position: left bottom;
  padding: 50px 0;
}
.landing_page1_block6_subwrapper {
  width: 820px;
  margin: 0 0 0 auto;
}
.landing_page1_block6 .text_wrapper p {
  font-size: 24px;
  font-weight: var(--main-font-Medium);
  line-height: 31px;
}

.landing_page1_list_wrapper h4 {
  font-size: 35px;
  line-height: 40px;
  font-weight: var(--main-font-SemiBold);
  color: #05529a;
  margin-bottom: 20px;
}
.landing_page1_list_wrapper p {
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage/images/l_page1_texticon.png);
  padding-left: 30px;
  background-repeat: no-repeat;
  background-position: left top 6px;
  font-weight: var(--main-font-Medium);
}
.landing_page1_block7 {
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage/images/l_page1_image7.webp);
  background-repeat: no-repeat;
  background-position: right bottom;
  padding: 50px 0;
}
.landing_page1_block7_subwrapper {
  width: 960px;
}
.text_wrapper .landing_page1_list_wrapper {
  margin-bottom: 20px;
}
.landing_page1_block7 .text_wrapper p {
  font-size: 24px;
  font-weight: var(--main-font-Medium);
  line-height: 31px;
}
.landing_page1_block7 .text_wrapper .landing_page1_list_wrapper p {
  font-size: 20px;
  line-height: 26px;
}

.landing_page1_block8_subwrapper {
  background: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage/images/l_page1_image8.webp),
    linear-gradient(
      90deg,
      rgba(1, 51, 102, 1) 0%,
      rgba(0, 26, 55, 1) 20%,
      rgba(1, 51, 103, 1) 100%
    );
  padding: 26px;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: auto 100%;
  padding-left: 400px;
  padding-left: 500px;
}
.landing_page1_block8_subwrapper h2 {
  font-size: 40px;
  line-height: 42px;
  font-weight: var(--main-font-SemiBold);
  margin-bottom: 20px;
  text-transform: uppercase;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(182, 183, 184, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.landing_page1_block8_colcontainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.landing_page1_block8_colcontainer .col {
  background: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage/images/l_page1_image10.png),
    linear-gradient(180deg, rgba(37, 185, 238, 1) 0%, rgba(1, 140, 201, 1) 100%);
  padding: 20px;
  display: flex;
  align-items: center;
  border: 1px solid #d4d9e0;
  margin: 4px;
  width: calc(33% - 8px);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100% 100%;
}
.landing_page1_block8_colcontainer .col h3 {
  color: #fff;
  font-size: 30px;
  line-height: 36px;
  font-weight: var(--main-font-SemiBold);
}
.landing_page1_block8_colcontainer .col img {
  max-width: 100%;
  margin-right: 15px;
}
.landing_page1_block9 {
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage/images/l_page1_image9.webp);
  background-repeat: no-repeat;
  background-position: right bottom -30px;
  padding: 50px 0;
}
.landing_page1_block9_subwrapper .landing_page1_list_wrapper {
  column-count: 2;
  width: 820px;
}
.landing_page1_block9_subwrapper .landing_page1_list_wrapper p {
  text-transform: uppercase;
  font-size: 30px;
}
.logo_col {
  background: #fff;
  padding: 9px;
  margin: 17px;
  width: calc(33.33% - 34px);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}
.logo_col_sub {
  border: 1px solid #062749;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}
.logo_col_btnwrapper {
  background-color: #062749;
  padding: 15px;
  position: relative;
  padding-bottom: 85px;
  height: calc(95% - 100px);
}
.logo_col_logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  height: 140px;
}
.logo_col_logo img {
  max-width: 100%;
}
.logo_col_btnwrapper button {
  cursor: pointer;
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage/images/l_page1_buttonbg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right bottom;
  border: 0;
  display: block;
  font-size: 24px;
  font-weight: var(--main-font-SemiBold);
  line-height: 31px;
  color: #fff;
  width: calc(100% - 20px);
  padding: 15px 0;
  border-radius: 5px;
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
}
.landing_page1_block10_subwrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -17px;
}
.landing_page1_block10 .title_sub_wrapper {
  justify-content: center;
}
.landing_page1_block11_subwrapper {
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage/images/l_page1_bg4.jpg);
  padding: 40px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-left: 680px;
  padding-bottom: 10px;
}
.landing_page1_block11_subwrapper h2 {
  font-size: 46px;
  line-height: 55px;
  font-weight: var(--main-font-Bold);
  text-transform: uppercase;
  background: rgb(161, 249, 255);
  background: linear-gradient(
    180deg,
    rgba(161, 249, 255, 1) 0%,
    rgba(0, 153, 221, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(0 1px 0 rgba(0, 0, 0, 0.4));
  margin-bottom: 10px;
}
.landing_page1_block11_subwrapper p {
  font-size: 24px;
  line-height: 33px;
  font-weight: var(--main-font-Medium);
  color: #fff;
  margin-bottom: 20px;
}
.landing_page1_block11_subwrapper img {
  position: absolute;
  left: 160px;
  bottom: 0;
}
.landing_page1_block11 {
  padding: 40px 0;
}

.logo_col_btnwrapper .landing_page1_list_wrapper p {
  color: #fff;
  font-size: 24px;
  font-weight: var(--main-font-Medium);
  margin-bottom: 25px;
}
.logo_col_btnwrapper .landing_page1_list_wrapper {
  padding: 0 30px;
}

.left-video-rightblock .title_main_wrapper {
  padding: 0;
}
.left-video-rightblock .title_sub_wrapper {
  display: block;
}
.left-video-rightblock .title_sub_wrapper p {
  font-size: 20px;
  line-height: 24px;
  font-weight: var(--main-font-Medium);
  color: #fff;
}
.left-video-rightblock .landing_page1_list_wrapper p {
  color: #fff;
  margin-bottom: 20px;
  font-size: 18px;
}
.left-video-rightblock .landing_page1_list_wrapper p:last-child {
  margin: 0;
}
.left-video-andtext-subwrapper {
  display: flex;
  background: rgb(1, 51, 103);
  background: linear-gradient(
    163deg,
    rgba(1, 51, 103, 1) 0%,
    rgba(1, 89, 148, 1) 100%
  );
  padding: 25px;
}
.left-video-rightblock {
  width: calc(63% - 25px);
  margin-left: 25px;
}
.left-video-leftblock {
  width: 37%;
}
.left-video-wrapper {
  width: 100%;
  height: 100%;
}
.left-video-wrapper iframe {
  width: 640px;
  height: 360px;
  margin: 0;
  padding: 0;
  border: 1px solid #5387bd;
}
.left-video-andtext {
  margin-bottom: 20px;
}

.left-video-rightblock .title_main_wrapper h2 {
  background: linear-gradient(
    180deg,
    rgb(255, 255, 255) 0%,
    rgb(233, 233, 233) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(0 1px 5px rgba(0, 0, 0, 0.2));
}
.logoandtext-colcontainer {
  display: flex;
  justify-content: space-between;
}
.right-textwrapper {
  width: calc(68% - 30px);
  margin-left: 30px;
}
.left-logowrapper {
  width: 32%;
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 41%,
    rgba(229, 238, 241, 1) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.left-logowrapper img {
  max-width: 100%;
  display: block;
}
.landing_page1_block10 {
  margin-bottom: 20px;
}
.right-textwrapper h3 {
  font-size: 28px;
  line-height: 32px;
  color: #013367;
  font-weight: var(--main-font-Medium);
  margin-bottom: 15px;
  margin-top: 10px;
}
.sublist-title h4 {
  font-size: 20px;
  line-height: 25px;
  color: #00131c;
  font-weight: var(--main-font-Medium);
  width: calc(89% - 15px);
  margin-left: 15px;
}
.sublist-title span {
  width: 11%;
}
.sublist-title span img {
  display: block;
  max-width: 100%;
}
.sublist-title {
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
}
.col {
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage/images/list_bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 17px;
  width: 50%;
  margin: 0 15px;
  background-position: right bottom;
}
.text-colcontainer {
  display: flex;
  justify-content: center;
  margin: 0 -15px;
}
.sub-list {
}
.sub-list p {
  color: #fff;
  font-size: 18px;
  font-weight: var(--main-font-Medium);
  position: relative;
  padding-left: 30px;
  margin-bottom: 20px;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}
.sub-list h5 {
  color: #fff;
  font-size: 20px;
  font-weight: var(--main-font-Medium);
  margin-bottom: 20px;
}
.sub-list p::before {
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  height: 10px;
  width: 10px;
  border: 5px solid #fff;
  border-radius: 50%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
}
.logoandtext.leftlogo-andtext,
.logoandtext.rightlogo-andtext {
  margin-bottom: 50px;
}
.rightlogo-andtext .logoandtext-colcontainer {
  flex-direction: row-reverse;
}
.rightlogo-andtext .right-textwrapper {
  margin-left: 0px;
  margin-right: 30px;
}


.top-to-btm {
  position: relative;
}
.icon-position {
  position: fixed;
  bottom: 150px;
  right: 25px;
  z-index: 20;
}
.icon-style {
  background-color: #0e5d89;
  border: 2px solid #fff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: #fff;
  cursor: pointer;
  animation: movebtn 3s ease-in-out infinite;
  transition: all 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-style svg {
  font-size: 30px;
}
.icon-style:hover {
  animation: none;
  background: #fff;
  color: #0e5d89;
  border: 2px solid #0e5d89;
}
@keyframes movebtn {
  0% {
    transform: translateY(0px);
    background-color: #ffffff;
    border: 2px solid #0e5d89;
    color: #0e5d89;
  }
  25% {
    transform: translateY(20px);
    box-shadow: 0 0 18px #0e5d89;
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-20px);
    background-color: #ffffff;
    border: 2px solid #0e5d89;
    color: #0e5d89;
  }
  100% {
    transform: translateY(0px);
  }
}
.landingOne .state svg {
  color: #303232;
}
.landingOne .form_style .formfild input {
  font-weight: var(--main-font-Bold);
}

@media only screen and (max-width: 1840px) {
  .landing-container {
    width: 96%;
  }
  .lpage_arrow_wrapper h2 {
    font-size: 22px;
    line-height: 25px;
  }
  .landing_page1_bannerblock {
    background-position: right 431px bottom, right top;
  }
  .landing-banner-left {
    width: 65%;
  }
  .landing-banner-right {
    width: calc(35% - 100px);
    margin-left: 92px;
  }
  .lpage_arrow_wrapper::after {
    top: -4px;
  }
  .landing_page1_block2 {
    background-position: right 660px top;
  }
  .landing_page1_block5_subwrapper h3 {
    font-size: 37px;
    line-height: 46px;
  }
  .landing_page1_block5_subwrapper p {
    font-size: 27px;
  }
  .landing_page1_block6 {
    background-position: left -100px bottom;
  }
  .landing_page1_block8_colcontainer .col h3 {
    font-size: 24px;
    line-height: 31px;
  }
}
@media only screen and (max-width: 1830px) {
  .left-video-rightblock {
    width: calc(61% - 25px);
    margin-left: 25px;
  }
  .left-video-leftblock {
    width: 39%;
  }
  .landing_page1_bannerblock {
    background-position: right 371px bottom, right top;
}
.landing_page1_block4 {background-position: right -100px bottom -90px;}
}
@media only screen and (max-width: 1780px) {
  .landing_page1_block11_subwrapper {
    padding-left: 610px;
  }
}
@media only screen and (max-width: 1760px) {
  .lpage_banner_list_item h2 {
    font-size: 15px;
  }
  .landing_page1_block2 {
    background-position: right 606px top;
  }
  .title_wrapper h2 {
    margin-bottom: 10px;
  }
  .landing_page1_block2_subtitle {
    padding: 10px 13px;
  }
  .landing_page1_block7{background-position: right -130px bottom;}

  .landing_page1_bannerblock {
    background-position: right 291px bottom, right top;
}
}
@media only screen and (max-width: 1740px) {
  .left-video-rightblock {
    width: calc(59% - 25px);
    margin-left: 25px;
  }
  .left-video-leftblock {
    width: 41%;
  }
}
@media only screen and (max-width: 1720px) {
  .landing_page1_block7 {
    background-position: right -156px bottom;
  }
  .landing_page1_block9 {
    background-position: right -250px bottom -30px;
  }
  .landing_page1_block4 {
    background-position: right -131px bottom -90px;
}
}
@media only screen and (max-width: 1680px) {
  .lpage_banner_textwrapper p {
    font-size: 26px;
  }
  .title_wrapper h2 span {
    font-size: 31px;
    line-height: 40px;
  }
  .landing_page1_block2_subtitle h3 {
    font-size: 20px;
    line-height: 25px;
    width: 686px;
  }
  .landing_page1_block4 {
    background-position: right -143px bottom -90px;
    background-size: 71%;
}
}
@media only screen and (max-width: 1670px) {
  .lpage_banner_titlewrapper h1 {
    font-size: 50px;
    line-height: 50px;
  }
  .lpage_banner_sub_titlewrapper {
    padding: 25px;
    width: calc(795px - 70px);
  }
  .lpage_banner_sub_titlewrapper h3 {
    font-size: 27px;
    line-height: 32px;
  }
  .lpage_banner_textwrapper {
    width: 654px;
  }
  .form_title_wrapper h2 span {
    font-size: 26px;
    line-height: 28px;
  }
  .form_title_wrapper {
    padding: 12px;
  }
  .lpage_arrow_wrapper h2 {
    font-size: 20px;
  }
  .form_text p {
    font-size: 16px;
    line-height: 21px;
  }
  .landing_page1_bannerblock {
    background-position: right 382px bottom, right top;
  }
  .landing_page1_block8_colcontainer .col h3 {
    font-size: 18px;
    line-height: 24px;
  }
  .left-video-rightblock {
    width: calc(57% - 25px);
    margin-left: 25px;
  }
  .left-video-leftblock {
    width: 43%;
  }
}
@media only screen and (max-width: 1620px) {
  .landing_page1_block2 {
    background-position: right 526px top;
  }
  .landing_page1_block5_subwrapper {
    padding-left: 422px;
  }
  .landing_page1_block6 {
    background-position: left -160px bottom;
  }
  .landing_page1_block7 {
    background-position: right -123px bottom;
    background-size: 58%;
  }
  .logo_col {
    margin: 7px;
    width: calc(33.33% - 14px);
  }
  .landing_page1_block10_subwrapper {
    margin: 0 -7px;
  }
  .landing_page1_block11_subwrapper h2 {
    font-size: 36px;
    line-height: 43px;
  }
  .landing_page1_block11_subwrapper p {
    font-size: 21px;
    line-height: 33px;
  }

}
@media only screen and (max-width: 1600px) {
  .form_style .formWrapperClass {
    padding: 10px 0 0;
  }
  .lpage_banner_list_item {
    width: calc(50% - 10px);
    margin: 11px 5px;
  }
  .lpage_banner_textwrapper {
    width: 580px;
  }
  .landing_page1_bannerblock {
    background-position: right 312px bottom, right top;
}
}
@media only screen and (max-width: 1580px) {
  .left-video-rightblock {
    width: calc(55% - 25px);
    margin-left: 25px;
  }
  .left-video-leftblock {
    width: 45%;
  }
}
@media only screen and (max-width: 1560px) {
  .landing_page1_block4 {
    background-position: right -117px bottom  -90px;
    background-size: 65%;
  }
  .landing_page1_block9 {
    background-position: right -350px bottom -30px;
  }
  .landing_page1_block6 {
    background-position: left -126px bottom;background-size: 60%;
}
}
@media only screen and (max-width: 1540px) {
  .lpage_banner_titlewrapper h1 {
    font-size: 40px;
    line-height: 40px;
  }
  .lpage_banner_sub_titlewrapper h3 {
    font-size: 22px;
    line-height: 27px;
  }
  .lpage_banner_sub_titlewrapper {
    padding: 20px;
    width: calc(661px - 70px);
  }
  .lpage_banner_textwrapper p {
    font-size: 24px;
    line-height: 32px;
  }
  .form_style .formfild input {
    padding: 9.5px 14px;
  }
  .form_style .MuiFormLabel-root {
    top: -6px;
  }
  .form_style .form_radio,
  .form_style .form_text {
    margin-bottom: 10px;
  }
  .landing_page1_bannerblock {
    background-position: right 332px bottom, right top;
  }
  .landing-banner-right {
    width: calc(35% - 43px);
    margin-left: 43px;
  }
  .landing-banner-left {
    width: 53%;
  }
  .lpage_arrow_wrapper {
    padding: 35px 60px 35px 40px;
  }
  .lpage_arrow_wrapper::after {
    top: -9px;left: 5px;
  }
  .landing_page1_bannerblock {
    background-position: right 392px bottom, right top;
  }
  .landing_page1_block2 {
    background-position: right 479px top;
  }
  .landing_page1_block5_subwrapper h3 {
    font-size: 30px;
    line-height: 37px;
  }
  .landing_page1_block8_colcontainer .col img {
    width: 65px;
  }
  .landing_page1_block8_subwrapper {
    padding-left: 400px;
  }
}
@media only screen and (max-width: 1520px) {
  .landing_page1_block2_video_wrapper {
    padding-bottom: 59.2%;
  }

  .logo_col_btnwrapper button {
    font-size: 18px;
  }
}
@media only screen and (max-width: 1500px) {
  .landing_page1_bannerblock {
    background-position: right 342px bottom, right top;
  }
  .landing_page1_block2 {
    background-image: none;
  }

  .left-video-wrapper iframe {
    width: 556px;
    height: 318px;
  }
  .left-video-leftblock {
    width: 42%;
  }
  .left-video-rightblock {
    width: calc(58% - 25px);
    margin-left: 25px;
  }
}
@media only screen and (max-width: 1460px) {
  .lpage_banner_textwrapper {
    width: 529px;
  }
  .landing_page1_block4 {
    background-position: right -42px bottom -90px;
    background-size: 54%;
}
}
@media only screen and (max-width: 1440px) {
  .text_wrapper h2 {
    font-size: 32px;
    line-height: 36px;
  }
  .sub-list p {
    margin-bottom: 15px;
  }
  .left-video-rightblock .landing_page1_list_wrapper p {
    margin-bottom: 10px;
  }
  .logo_col_btnwrapper .landing_page1_list_wrapper p {
    font-size: 20px;
    line-height: 25px;
  }
  .logo_col_btnwrapper .landing_page1_list_wrapper {
    padding: 0;
  }
}
@media only screen and (max-width: 1420px) {
  .landing_page1_block6_subwrapper {
    width: 660px;
  }
  .landing_page1_block6 .text_wrapper p {
    font-size: 22px;
    line-height: 28px;
  }
  .landing_page1_block11_subwrapper h2 {
    font-size: 28px;
    line-height: 37px;
  }
  .landing_page1_block11_subwrapper p {
    font-size: 18px;
    line-height: 29px;
  }
  .left-video-rightblock .landing_page1_list_wrapper p {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .left-video-leftblock {
    width: 45%;
  }
  .left-video-rightblock {
    width: calc(55% - 25px);
    margin-left: 25px;
  }
  .landing_page1_block7 .text_wrapper{width: 88%;}
}
@media only screen and (max-width: 1400px) {
  .landing_page1_bannerblock {
    background-position: right 232px bottom, right top;
}
}
@media only screen and (max-width: 1380px) {
  .lpage_banner_textwrapper {
    width: 500px;
  }
}
@media only screen and (max-width: 1366px) {
  .landing_page1_block5_subwrapper h3 span {
    font-size: 64px;
    line-height: 70px;
  }
  .lpage_banner_titlewrapper h1 {
    font-size: 36px;
    line-height: 36px;
  }
  .lpage_arrow_wrapper h2 {
    font-size: 18px;
    line-height: 21px;
  }
  .lpage_banner_sub_titlewrapper {
    margin-bottom: 25px;
  }
  .lpage_banner_textwrapper {
    width: 459px;
  }
  .landing_page1_block4 {
    background-position: right -40px bottom  0px;
  }
  .landing_page1_block4 .text_wrapper{width: 88%;}
  .landing_page1_block5_subwrapper h3 {
    font-size: 24px;
    line-height: 34px;
  }
  .landing_page1_block5_subwrapper p {
    font-size: 20px;
  }
  .landing_page1_block9_subwrapper .landing_page1_list_wrapper p {
    text-transform: uppercase;
    font-size: 22px;
  }
  .landing_page1_block9_subwrapper .landing_page1_list_wrapper {
    width: 636px;
  }
  .lpage_arrow_wrapper::after {
    top: -11px;
    
}
}
@media only screen and (max-width: 1340px) {
  .landing_page1_block2_video_wrapper {
    height: 100%;
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 1330px) {
  .form_title_wrapper h2 span {
    font-size: 22px;
    line-height: 24px;
  }
  .lpage_banner_sub_titlewrapper {
    padding: 15px;
    width: calc(544px - 30px);
  }
  .lpage_banner_sub_titlewrapper h3 {
    font-size: 18px;
    line-height: 26px;
  }
  .landing-banner-right {
    width: calc(39% - 43px);
    margin-left: 43px;
  }
  .lpage_banner_textwrapper p {
    font-size: 18px;
    line-height: 27px;
  }
  .lpage_banner_textwrapper {
    width: 379px;
  }
  .lpage_banner_titlewrapper h1 {
    font-size: 36px;
    line-height: 38px;
  }
  .left-video-andtext-subwrapper {
    align-items: center;
  }
  .left-video-rightblock .title_main_wrapper h2 {
    font-size: 32px;
    line-height: 37px;
  }
  .left-video-rightblock .title_sub_wrapper p {
    font-size: 16px;
    line-height: 20px;
  }
  .left-video-leftblock {
    width: 48%;
  }
  .left-video-rightblock {
    width: calc(52% - 25px);
    margin-left: 25px;
  }
}
@media only screen and (max-width: 1300px) {
  .landing_page1_block7_subwrapper {
    width: 830px;
  }
  .landing_page1_block8_colcontainer .col img {
    width: 52px;
  }
  .landing_page1_block8_colcontainer .col h3 {
    font-size: 16px;
    line-height: 21px;
  }
  .landing_page1_block8_subwrapper {
    padding-left: 355px;
  }
}
@media only screen and (max-width: 1280px) {
  .landing_page1_block6 {
    background-position: left -74px bottom;
  }
  .landing_page1_block11_subwrapper img {
    position: absolute;
    left: 80px;
    bottom: 0;
  }
  .landing_page1_block11_subwrapper {
    padding: 20px;
    padding-left: 570px;
  }
  .left-video-leftblock {
    width: 50%;
  }
  .left-video-rightblock {
    width: calc(50% - 25px);
    margin-left: 25px;
  }
}
@media only screen and (max-width: 1240px) {
  .lpage_banner_titlewrapper h1 {
    font-size: 32px;
  }
  .logo_col {
    width: calc(50% - 14px);
  }
  .landing_page1_block7{background-size: 65%;}
}
@media only screen and (max-width: 1199px) {

  .landing_page5_bannerblock .lpage_logowrapper {
    margin-bottom: 15px!important;
}
.landing_page1_block7 .text_wrapper{width: auto;}
.landing_page1_block4 .text_wrapper{width: auto;}
.landing_page5_bannerblock{ padding-top: 20px!important;}

.lpage_banner_textwrapper p br{ display: none;}
  .lpage_logowrapper {
    margin-bottom: 30px;
  }
  .landing-banner-left {
    width: 100%;
  }
  .landing-banner-right {
    width: calc(100% - 0px);
    margin-left: 0;
  }
  .landing_page1_bannerblock {
    background-position: right 92000px bottom -70px, right top;
  }
  .lpage_banner_textwrapper {
    width: auto;
  }
  .lpage_banner_textwrapper p {
    font-size: 24px;
    line-height: 33px;
  }
  .lpage_banner_sub_titlewrapper {
    padding: 15px;
    width: auto;
  }
  .lpage_banner_sub_titlewrapper h3 {
    font-size: 24px;
    line-height: 31px;
  }
  .lpage_banner_titlewrapper h1 {
    font-size: 46px;
    line-height: 52px;
    display: inline;
    margin-left: 5px;
    background: inherit;
    -webkit-background-clip: inherit;
    -webkit-text-fill-color: inherit;
    color: #24b5dd;
  }
  .lpage_banner_titlewrapper h1 span {
    background: inherit;
    -webkit-background-clip: inherit;
    -webkit-text-fill-color: inherit;
    color: #0f5590;
  }
  .form_title_wrapper h2 span {
    font-size: 30px;
    line-height: 36px;
    display: inline-block;
  }
  .lpage_arrow_wrapper {
    padding: 20px;
    /* color: #fcaf2a; */
    background: rgb(87,254,246);
    background: linear-gradient(180deg, rgba(87,254,246,1) 0%, rgba(1,197,254,1) 100%);
    margin: 20px 0;
  }
  .lpage_arrow_wrapper::after {
    display: none;
  }
  .lpage_banner_titlewrapper {
    margin-bottom: 20px;
  }
  .lpage_banner_list_wrapper {
    margin-top: 20px;
  }
  .landing_page1_block2_left {
    width: 100%;
  }
  .landing_page1_block2_right {
    width: 100%;
  }
  .landing_page1_block2_colcontainer {
    flex-wrap: wrap;
  }
  .landing_page1_block2 .text_wrapper {
    width: auto;
  }
  .landing_page1_block2_video_wrapper {
    height: auto;
    padding-bottom: 52.2%;
    margin-top: 20px;
  }
  .landing_page1_block2 .title_wrapper,
  .landing_page1_block2_subtitle h3 {
    width: auto;
  }
  .landing_page1_block3_left {
    width: calc(100% - 0px);
    margin-right: 0;
  }
  .landing_page1_block3_right {
    width: 100%;
    margin-top: 20px;
  }
  .landing_page1_block4 {
    background-position: right top;
    background-size: 108%;
    padding-top: 49%;
  }
  .landing_page1_block4_subwrapper {
    width: auto;
  }
  .landing_page1_block5_subwrapper {
    padding: 40px;
    height: auto;
    min-height: inherit;
    flex-wrap: wrap;
  }
  .landing_page1_block5_text {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
  .landing_page1_block5_subwrapper img {
    position: inherit;
    left: inherit;
    bottom: 0;
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }
  .landing_page1_block6 {
    background-position: left top;
    background-size: 106%;
    padding-top: 54%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .landing_page1_block6_subwrapper {
    width: auto;
  }
  .landing_page1_block7 {
    background: none;
    padding-top: 0;
    padding: 0 15px 40px 15px;
  }
  .landing_page1_block7_subwrapper {
    width: auto;
  }
  .landing_page1_block8_subwrapper {
    background: linear-gradient(
      90deg,
      rgba(1, 51, 102, 1) 0%,
      rgba(0, 26, 55, 1) 20%,
      rgba(1, 51, 103, 1) 100%
    );
    padding: 26px;
  }
  .landing_page1_block9_subwrapper .landing_page1_list_wrapper {
    width: auto;
    column-count: 3;
    padding: 0 15px;
  }
  .landing_page1_block9 {
    background-position: right top;
    padding-top: 30%;
    background-size: 100%;
    padding-bottom: 10px;
  }
  .landing_page1_block11_subwrapper {
    padding: 20px;
    padding-left: 20px;
    padding-bottom: 0;
  }
  .landing_page1_block11_subwrapper img {
    position: inherit;
    left: 0;
    bottom: 0;
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
  .left-video-andtext-subwrapper,
  .logoandtext-colcontainer {
    flex-wrap: wrap;
  }
  .left-video-leftblock {
    width: 100%;
    margin-bottom: 15px;
    background: #28b6f5;
    padding: 3.5% 0;
  }
  .left-video-rightblock {
    width: calc(100%);
    margin-left: 0;
  }
  .left-video-wrapper {
    position: relative;
    padding-bottom: 52.2%;
  }
  .left-video-wrapper iframe {
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border: 0;
  }
  .left-logowrapper {
    width: 100%;
    border: 1px solid #d6dfe1;
  }
  .right-textwrapper {
    width: calc(100%);
    margin-left: 0;
  }
  .logoandtext.leftlogo-andtext {
    margin-bottom: 20px;
  }
  .logoandtext.leftlogo-andtext,
  .logoandtext.rightlogo-andtext {
    margin-bottom: 20px;
  }
  .rightlogo-andtext .right-textwrapper {
    margin-left: 0;
    margin-right: 0;
  }
  .left-video-andtext-subwrapper {
    padding: 15px;
  }
  .landing_page1_block11 {
    padding: 20px 0;
    padding-top: 0;
  }
  .form_style .MuiFormHelperText-root.Mui-error,
  .form_style .MuiFormHelperText-root,
  .l2form_style .MuiFormHelperText-root.Mui-error,
  .l4form_style .MuiFormHelperText-root.Mui-error,
  .new_l4form_style .MuiFormHelperText-root.Mui-error,
  .new_l4form_style .MuiFormHelperText-root,
  .landingFour .MuiFormHelperText-contained,
  .l2form_style .state .MuiFormHelperText-contained {
    position: inherit !important;
    left: inherit !important;
    top: inherit !important;
    transform: inherit !important;
    background: none !important;
    padding: 0 !important;
    font-size: 14px !important;
    border-radius: 0 !important;
    color: #f00 !important;
    border: none !important;
    margin-left: 0 !important;
  }
  .form_style .MuiFormHelperText-root.Mui-error::before,
  .new_l4form_style .MuiFormHelperText-root.Mui-error::before,
  .new_l4form_style .MuiFormHelperText-root::before,
  .form_style .MuiFormHelperText-root::before,
  .l2form_style .MuiFormHelperText-root.Mui-error::before,
  .l4form_style .MuiFormHelperText-root.Mui-error::before,
  .landingFour .MuiFormHelperText-contained:after,
  .l2form_style .state .MuiFormHelperText-contained:after {
    display: none !important;
  }
  .form_style .MuiFormHelperText-root.Mui-error::after,
  .new_l4form_style .MuiFormHelperText-root.Mui-error::after,
  .new_l4form_style .MuiFormHelperText-root::after,
  .form_style .MuiFormHelperText-root::after,
  .l2form_style .MuiFormHelperText-root.Mui-error::after,
  .l4form_style .MuiFormHelperText-root.Mui-error::after,
  .landingFour .MuiFormHelperText-contained::before,
  .l2form_style .state .MuiFormHelperText-contained::before {
    display: none;
  }
  .right-textwrapper h3 {
    margin-top: 18px;
  }
  .landing_page1_bannerblock {
    padding-bottom: 25px;
  }
  .landing_page1_block2 {
    padding-top: 25px;
  }
  .title_wrapper h2 span {
    display: initial;
  }
  .title_wrapper h2 span:nth-child(2) {
    margin-left: 5px;
  }
  .lpage_logowrapper img {
    margin: 0 auto;
  }
}
@media only screen and (max-width: 991px) {
  .landing_page1_block8_colcontainer .col {
    width: calc(50% - 8px);
  }
  .text-colcontainer {
    margin: 0;
    flex-wrap: wrap;
  }
  .col {
    margin: 10px 0;
    width: 100%;
  }
  .logo_col_btnwrapper .landing_page1_list_wrapper p {
    font-size: 18px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 768px) {
  .landing_page1_block8_subwrapper h2 {
    font-size: 26px;
    line-height: 30px;
  }
  .landing_page1_block8_subwrapper {
    padding: 15px;
  }
  .landing_page1_block9 {
    background-position: right 43% top;
    padding-top: 40%;
    background-size: 134%;
    padding-bottom: 10px;
  }
  .text_wrapper h2,
  .title_main_wrapper h2,
  .landing_page1_block3 .text_wrapper h2 {
    text-shadow: none;
    font-size: 26px;
    line-height: 29px;
    background: #fff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(0 1px 5px rgba(0, 0, 0, 0.2));
  }
  .landing_page1_block3_subwrapper {
    padding: 15px;
  }
  .landing_page1_block3 .text_wrapper {
    position: relative;
    padding-left: 0;
  }
  .landing_page1_block3 .text_wrapper::before {
    display: none;
  }
  .text_wrapper p {
    font-size: 18px !important;
    line-height: 26px !important;
  }
  .landing_page1_block4 {
    padding-bottom: 20px;
  }
  .lpage_banner_list_item {
    width: 100%;
    margin: 11px 0px;
  }
  .lpage_banner_titlewrapper h1 {
    font-size: 32px;
    line-height: 42px;
  }
  .logo_col {
    width: calc(100% - 14px);
  }
  .landing_page1_block9_subwrapper .landing_page1_list_wrapper {
    width: auto;
    column-count: 2;
  }
  .logo_col_btnwrapper {
    padding: 23px 15px;
    position: relative;
    padding-bottom: 15px;
    height: auto;
  }
  .logo_col_btnwrapper button {
    width: 100%;
    padding: 15px 15px;
    border-radius: 5px;
    position: inherit;
    left: inherit;
    bottom: inherit;
    transform: inherit;
    line-height: 22px;
  }
  .landing_page1_block11_subwrapper h2 {
    background: rgb(161, 249, 255);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
@media only screen and (max-width: 560px) {
  .landing_page1_block9_subwrapper .landing_page1_list_wrapper {
    width: auto;
    column-count: 1;
  }
  .landing_page1_block8_colcontainer .col {
    width: calc(100% - 8px);
  }
}
@media only screen and (max-width: 480px) {
  .lpage_logowrapper {
    margin-bottom: 20px;
  }
  .form_style .form_radio .MuiFormControlLabel-root {
    width: 29%;
  }
  .submitbtnsection button {
    font-size: 30px;
    line-height: 32px;
    padding: 13px;
  }
  .lpage_banner_titlewrapper h1 {
    font-size: 26px;
    line-height: 35px;
  }
  .lpage_banner_sub_titlewrapper h3 {
    font-size: 18px;
    line-height: 24px;
  }
  .lpage_banner_textwrapper p {
    font-size: 18px;
    line-height: 24px;
  }
  .lpage_banner_sub_titlewrapper {
    margin-bottom: 20px;
  }
  .form_title_wrapper h2 span {
    font-size: 22px;
    line-height: 28px;
  }
  .sublist-title {
    flex-wrap: wrap;
  }
  .sublist-title span {
    width: 100%;
    margin-bottom: 10px;
  }
  .sublist-title h4 {
    width: auto;
    margin-left: 0;
    text-align: center;
  }
  .sublist-title span img {
    margin: 0 auto;
  }
  .left-video-rightblock .title_sub_wrapper p {
    font-size: 16px;
    line-height: 22px;
    margin-top: 10px;
  }
  .right-textwrapper h3 {
    font-size: 24px;
    line-height: 27px;
    margin-bottom: 15px;
    margin-top: 20px;
  }
  .logoandtext.leftlogo-andtext,
  .logoandtext.rightlogo-andtext {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 380px) {
  .form_style .form_radio .MuiFormControlLabel-root {
    width: 48%;
  }
}
